.shadow {
  &Top,
  &Bottom {
    width: 100%;
  }

  &Top {
    height: 46px;
    background: linear-gradient(
      180deg,
      #ffffff 0%,
      #101010 0%,
      rgba(13, 13, 13, 0.86) 0%,
      rgba(5, 5, 5, 0.37) 0%,
      rgba(4, 4, 4, 0.29) 43.16%,
      rgba(0, 0, 0, 0) 100%
    );
  }

  &Bottom {
    height: 63px;
    background: linear-gradient(
      0deg,
      #ffffff 0%,
      #101010 0%,
      rgba(13, 13, 13, 0.86) 14.04%,
      rgba(0, 0, 0, 0) 100%
    );
  }
}
