@mixin onEnter() {
  opacity: 0;
}

@mixin onExit() {
  opacity: 0.8;
}

.enter {
  @include onEnter();
}

.exit {
  @include onExit();
}

.enterActive {
  @include onExit();
}

.exitActive {
  @include onEnter();
}

.enterActive {
  transition: all 600ms;
  transition-delay: 300ms;
}

.exitActive {
  transition: all 300ms;
}
