@tailwind base;

:root {
  --color-primary: #6f6d88;
}

.theme-teacher {
  --color-primary: #8ea38d;
}

@tailwind utilities;

@layer utilities {
  /* Default Font Size is 1rem */

  .heading-1 {
    @apply font-title;
    font-size: 1.5rem;
    letter-spacing: 0;
    line-height: 2rem;
  }

  .heading-2 {
    @apply font-mono;
    font-size: 0.95rem;
    letter-spacing: 0.094rem;
    line-height: 1.375rem;
    text-transform: uppercase;
  }

  .heading-3 {
    @apply font-mono;
    font-size: 0.75rem;
    letter-spacing: 0.094rem;
    line-height: 1.1rem;
    text-transform: uppercase;
  }

  .heading-4 {
    @apply font-mono;
    font-size: 0.6rem;
    letter-spacing: 0.063rem;
    line-height: 1rem;
    text-transform: uppercase;
  }

  .body-1 {
    @apply font-regular;
    font-size: 0.87rem;
    letter-spacing: 0.032rem;
    line-height: 1.375rem;
  }

  .body-2 {
    @apply font-regular;
    font-size: 0.75rem;
    letter-spacing: 0.038rem;
    line-height: 1.1rem;
  }

  .body-3 {
    @apply font-regular;
    font-size: 0.65rem;
    letter-spacing: 0.038rem;
    line-height: 1rem;
  }

  .body-medium-1 {
    @apply font-medium;
    font-size: 0.9rem;
    letter-spacing: 0.032rem;
    line-height: 1.375rem;
  }

  .body-medium-2 {
    @apply font-medium;
    font-size: 0.75rem;
    letter-spacing: 0.038rem;
    line-height: 1.1rem;
  }

  .body-medium-3 {
    @apply font-medium;
    font-size: 0.65rem;
    letter-spacing: 0.038rem;
    line-height: 1rem;
  }

  .number-1 {
    @apply font-regular;
    font-size: 1.5rem;
    letter-spacing: 0.094rem;
    line-height: 1.5rem;
    text-transform: uppercase;
  }
}

/*
Conversion tables (16px base)
*****************************

PX  to  REM 
----------------
1px     0.06rem
2px     0.13rem
3px     0.19rem
4px     0.3rem
5px     0.3rem
6px     0.4rem
8px     0.5rem
10px    0.6rem
12px    0.75rem
14px    0.9rem
15px    0.9rem
16px    1rem
18px    1.1rem
20px    1.3rem
24px    1.5rem
25px    1.6rem
28px    1.8rem
32px    2rem
36px    2rem
40px    3rem
44px    3rem
48px    3rem
50px    3rem
56px    4rem
64px    4rem
72px    5rem
75px    5rem
80px    5rem
90px    6rem
100px   6rem

REM   to  PX
----------------
0.01rem   0.16px
0.03rem   0.5px
0.05rem   0.8px
0.08rem   1.3px
0.1rem    1.6px
0.15rem   2px
0.2rem    3px
0.5rem    8px
1rem      16px
2rem      32px
3rem      48px
4rem      64px
5rem      80px
6rem      96px
8rem      128px
10rem     160px
15rem     240px
20rem     320px
30rem     480px
40rem     640px
50rem     800px
60rem     960px
80rem     1280px
100rem    1600px
*/
