@keyframes show {
  from {
    opacity: 0.4;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.alert {
  animation-name: show;
  animation-duration: 300ms;
  animation-timing-function: ease-in-out;
}
