/* RANDOM */
.floating-middle {
  &-center {
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &-right {
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

/* Safe spaces for devices with Notches. Value is 0px when it is not needed */
.safe-area-pt {
  padding-top: env(safe-area-inset-top);
  // padding-top: 30px; // Used for Mockups
}
.safe-area-pr {
  padding-right: env(safe-area-inset-right);
}
.safe-area-pb {
  padding-bottom: env(safe-area-inset-bottom);
  // padding-bottom: 30px; // Used for Mockups
}
.safe-area-pl {
  padding-left: env(safe-area-inset-left);
}
.safe-area-mt {
  margin-top: env(safe-area-inset-top);
}
.safe-area-mr {
  margin-right: env(safe-area-inset-right);
}
.safe-area-mb {
  margin-bottom: env(safe-area-inset-bottom);
}
.safe-area-ml {
  margin-left: env(safe-area-inset-left);
}
