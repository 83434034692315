/*
THIS FILE IS AUTOGENERATED

Any manual changes you make in this file will be lost.

Why?
Tailwind is a great framework that provides css utilities via classes.
Sometimes, we need to consume them from custom css/scss code, and
colors cannot be imported directly. This solves that problema,
using tailwind.config.js as a source of truth
*/

$color-primary: var(--color-primary);
$color-transparent: transparent;
$color-white: white;
$color-black: #212121;
$color-nude: #F4F0ED;
$color-green: #627370;
$color-purple: #6f6d88;
$color-gray-100: #fafafa;
$color-gray-200: #f5f5f5;
$color-gray-300: #eeeeee;
$color-gray-400: #e0e0e0;
$color-gray-500: #bdbdbd;
$color-gray-600: #9e9e9e;
$color-gray-700: #757575;
$color-gray-800: #616161;
$color-gray-900: #424242;
$color-red-100: #fff5f5;
$color-red-200: #fed7d7;
$color-red-300: #feb2b2;
$color-red-400: #fc8181;
$color-red-500: #f56565;
$color-red-600: #e53e3e;
$color-red-700: #c53030;
$color-red-800: #9b2c2c;
$color-red-900: #742a2a;
$color-yellow-100: #fffff0;
$color-yellow-200: #fefcbf;
$color-yellow-300: #faf089;
$color-yellow-400: #f6e05e;
$color-yellow-500: #ecc94b;
$color-yellow-600: #d69e2e;
$color-yellow-700: #b7791f;
$color-yellow-800: #975a16;
$color-yellow-900: #744210;
