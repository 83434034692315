.enter {
  .overlay {
    transition: all 150ms ease-out;
    opacity: 0;
  }

  .box {
    transition: all 150ms ease-out;
    opacity: 0.5;
    transform: scale(0.95);
  }
}

.enterActive {
  .overlay {
    transition: all 150ms ease-out;
    opacity: 1;
  }

  .box {
    transition: all 150ms ease-out;
    opacity: 1;
    transform: scale(1);
  }
}

.exit {
  .overlay {
    transition: all 150ms ease-out;
    opacity: 1;
  }

  .box {
    transition: all 150ms ease-out;
    opacity: 1;
    transform: scale(1);
  }
}

.exitActive {
  .overlay {
    transition: all 150ms ease-out;
    opacity: 0;
  }

  .box {
    transition: all 150ms ease-out;
    opacity: 0.5;
    transform: scale(0.95);
  }
}
