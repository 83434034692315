$boxHeight: 180px;

.bgPosition {
  background-position: center (0 - $boxHeight);
}

.box {
  height: $boxHeight;
}

.overlay {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  width: 50px;
  height: 50px;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 0px 999px rgba(0, 0, 0, 0.8);
  }
}
