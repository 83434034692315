@mixin fullScreen {
  width: 100%;
  height: 100%;
  left: 0;
  bottom: 0;
}

.overlay {
  @include fullScreen();
  position: fixed;
  background: black;
  opacity: 0.7;
}

.content {
  position: absolute;
  overflow-y: auto;

  &FullScreen {
    @include fullScreen();
  }

  &ContentBase {
    width: 100%;
    max-height: 100%;
    left: 0;
    bottom: 0;
  }
}

.root {
  @include fullScreen();
  position: fixed;
}

.enter {
  display: block;
  bottom: -100%;

  .overlay {
    opacity: 0;
  }
}

.enterActive {
  bottom: 0;
  transition: all 300ms ease-out;

  .overlay {
    opacity: 0.7;
    transition: all 300ms ease-out;
  }
}

.exit {
  bottom: 0;

  .overlay {
    opacity: 0.7;
  }
}

.exitActive {
  bottom: -100%;
  transition: all 300ms ease-in;

  .overlay {
    opacity: 0;
    transition: all 300ms ease-out;
  }
}

.close {
  top: 50%;
  transform: translateY(-50%);
}
