@mixin onEnter() {
  opacity: 0.3;
  transform: scale(0.99);
}

@mixin onExit() {
  opacity: 1;
  transform: scale(1);
}

.enter {
  @include onEnter();
}

.exit {
  @include onExit();
}

.enterActive {
  @include onExit();
}

.exitActive {
  @include onEnter();
}

.enterActive,
.exitActive {
  transition: all 175ms;
}
