.icon {
  top: 50%;
  transform: translateY(-50%);
}

$size: 20px;

.radio,
.checkbox {
  position: absolute;
  opacity: 0;
  height: 0;
  width: 0;
}

.radioMark,
.checkboxMark {
  position: relative;
  height: 26px;
  width: 26px;
  left: -2px;

  &:before,
  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
  }

  &:before {
    width: $size;
    height: $size;
    background: white;
    border: solid 1px #bdbdbd;
  }
}

.radioMark {
  &:before {
    border-radius: $size;
  }
}

.radio:checked + .radioMark,
.checkbox:checked + .checkboxMark {
  &:after {
    $size: 12px;
    width: $size;
    height: $size;
    background: #8ca38e;
  }
}

.radio:checked + .radioMark {
  &:after {
    border-radius: $size;
  }
}
