@import './tailwind-variables';
@import './custom-class-utilities';

/* Reset */
* {
  -webkit-tap-highlight-color: transparent;
  box-sizing: border-box;

  &,
  &:focus {
    outline: none !important;
  }
}

input[type='number'] {
  -moz-appearance: textfield;
}
input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Base styles */

html,
body {
  font-family: 'Roboto-Regular';
  color: $color-gray-900;
  background: $color-gray-100;
  font-size: 16px; // for easy PX to REM conversion. We apply .body-1 as default

  @media (min-width: 700px) {
    font-size: 18px;
  }
}

html,
body,
#root {
  display: block;
  height: 100%;
  min-height: 100%;
}

sup {
  font-size: 70%;
}

/* PLATFORMS */

// Any platform except web
body:not(.web) {
  overflow: hidden;

  :not(input, textarea) {
    user-select: none;
  }
}

body.ios {
  // NOTE: These classes break StackNavigator. After we switched to ionic, the scroll got smooth automatically, maybe we should just delete them

  // .smooth-scroll-y {
  //   touch-action: pan-y;
  //   // overscroll-behavior-y: contain;
  //   overscroll-behavior-y: none;
  //   will-change: scroll-position;
  //   -webkit-overflow-scrolling: touch;
  // }

  // .smooth-scroll-x {
  //   touch-action: pan-x;
  //   // overscroll-behavior-x: contain;
  //   overscroll-behavior-x: none;
  //   will-change: scroll-position;
  //   -webkit-overflow-scrolling: touch;
  // }

  .space-for-keyboard {
    &-xs {
      height: 150px;
    }

    &-sm {
      height: 200px;
    }

    &-md {
      height: 250px;
    }

    &-lg {
      height: 300px;
    }
  }
}

body.android {
  // .smooth-scroll {
  //   &-y,
  //   &-x {
  //     scroll-behavior: smooth;
  //     -webkit-transform: translateZ(0);
  //     -webkit-backface-visibility: hidden;
  //     -webkit-overflow-scrolling: touch;
  //   }
  // }
}

/* CUSTOMIZATION: PLUGINS */

// Swipeable List
.swipeable-list {
  height: auto;
}
.swipeable-list-item__content {
  background-color: transparent;
}

// React Input Range (slider)
$input-range-primary-color: $color-primary;
$input-range-neutral-color: $color-gray-700;
$input-range-disabled-color: $color-gray-400;
$input-range-neutral-light-color: $color-gray-500;
$input-range-slider-border: 1px solid $color-gray-300;
$input-range-slider-background: white;
$input-range-font-family: 'Roboto-Regular';
$input-range-slider-width: 1.5rem;
$input-range-slider-height: 1.5rem;
$input-range-label-position-bottom: -1.6rem;
$input-range-label-value-position-top: -2.3rem;
$input-range-track-height: 0.5rem;
$transition-time: 150ms;
$input-range-slider-container-transition: left $transition-time ease-out !default;
$input-range-track-transition: left $transition-time ease-out,
  width $transition-time ease-out !default;
@import 'react-input-range/src/scss/index.scss';

.input-range__slider {
  box-shadow: 0 -2px 4px 0 rgba(140, 140, 140, 0.2),
    0 2px 4px 0 rgba(140, 140, 140, 0.3);
}

// Sentry Report widget
.sentry-error-embed {
  padding: 60px 20px 30px !important;

  button.close {
    background-color: $color-white !important;
    padding: 10px 40px !important;
    color: $color-red-500 !important;
    border: 2px solid $color-red-500 !important;
    margin: 20px auto !important;
  }
}

input#_capacitor-camera-input {
  position: fixed;
  left: -9999%;
}
