@mixin inactive {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0;
  }

  & > * {
    opacity: 0.5;
    transform: translateX(100%);
  }
}

@mixin active {
  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: black;
    opacity: 0.7;
  }

  & > * {
    opacity: 1;
    transform: translateX(0%);
  }
}

@mixin transition {
  &:before {
    transition: opacity 300ms;
  }

  & > * {
    transition: all 400ms ease-in-out;
  }
}

.enter {
  @include inactive();
}
.exit {
  @include active();
}
.enterActive {
  @include active();
}
.exitActive {
  @include inactive();
}
.enterActive,
.exitActive {
  @include transition();
}
.enterDone {
  @include active();
}
